var SVG_NS = 'http://www.w3.org/2000/svg';
var colorList = ['#7AF4FF', '#67D7FF', '#52B5FF', '#295BFF'];

import * as mapApi from '@/api/map'

// 自定义SVG图层 - 继承DOMOverlay
function SvgMarker(options) {
	console.log(options)
	TMap.DOMOverlay.call(this, options);
}

SvgMarker.prototype = new TMap.DOMOverlay();



//自定义的方法，用于设置myOverlay的html
SvgMarker.prototype.html = function(html) {
	this.dom.innerHTML = html;
}

//实现析构方法（类生命周期结束时会自动调用，用于释放资源等）
SvgMarker.prototype.destroy = function() {
	//移除dom
	this.dom.parentNode.removeChild(this.dom);
};
//以上自定义Overlay代码结束 =================================================

// 初始化
SvgMarker.prototype.onInit = function(options) {
	console.log(options)
	this.options = options.options;
	this.map = options.map;
};

// 销毁时需解绑事件监听
SvgMarker.prototype.onDestroy = function() {
	if (this.onClick) {
		this.dom.removeEventListener(this.onClick);
	}
};

// 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
SvgMarker.prototype.createDOM = function() {

	console.log(this.dom)



	var mapContainer = document.getElementById('container');

	var svg = document.createElementNS(SVG_NS, 'svg');
	console.log(svg)
	svg.id = 'svgDom';
	svg.setAttribute('width', mapContainer.offsetWidth);
	svg.setAttribute('height', mapContainer.offsetHeight);
	svg.style.cssText = 'position:absolute;top:0px;left:0px;';

	var svgDom = document.getElementById('svgDom');



	var group = []; // 定义g元素数组
	var circleShape = []; // 定义circle元素数组
	var textShape = []; // 定义text元素数组
	var area = [] //区域
	var tspan = []
	var textShape1 = []

	var rectShape = []
	var g = document.getElementsByTagName('g')
var rect = document.getElementsByTagName('rect')
	// 遍历传入参数，创建同等数量的svg下元素节点并绑定事件
	
		
		
		// if (v > 13) {
		// 	var a = Number(v.toFixed(1))
		// 	var b = Math.floor(v)			
		// 	// if((a-b)%1> 0.5){
		// 		var location ={}
		// 		location.lat = this.map.center.lat
		// 		location.lng = this.map.center.lng
		// 		mapApi.getMapAreaDetail(location).then(res=>{
		// 			console.log(res)
		// 			for (var k = 0; k < res.data.length; k++) {
		// 			// 在中心创建一个矩形	
		// 			var createCenter = this.map.projectToContainer(this.options[k].position);
		// 			group[k] = document.createElementNS(SVG_NS, 'g');
		// 			rectShape[k] = document.createElementNS(SVG_NS, 'rect');
		// 			rectShape[k].setAttribute('style', 'fill: #2F72F5;stroke:#FFFFFF;opacity:0.9;');
		// 			rectShape[k].setAttribute('x', createCenter.x-20);
		// 			rectShape[k].setAttribute('y', createCenter.y-20);
		// 			rectShape[k].setAttribute('rx', 10);
		// 			rectShape[k].setAttribute('ry', 10);			
		// 			rectShape[k].setAttribute('width', 60);
		// 			rectShape[k].setAttribute('height', 20);
		// 			group[k].appendChild(rectShape[k]);
					
		// 			// //绘制文字
		// 			// textShape[i] = document.createElementNS(SVG_NS, 'text');
		// 			// textShape[i].setAttribute('x', createCenter.x);
		// 			// textShape[i].setAttribute('y', createCenter.y + 13); // +5是为了让文字向下偏移5像素，使文字居中
		// 			// textShape[i].setAttribute('text-anchor', 'middle');
		// 			// textShape[i].setAttribute('fill', '#FFFFFF');
		// 			// textShape[i].setAttribute('font-size', '10px');
		// 			// textShape[i].innerHTML = data[i].text; //文字					
		// 			// svg.appendChild(group[i]);
		// 			// group[i].appendChild(textShape[i]);
		// 			console.log(group)
		// 			svgDom.replaceChild(group[k], g[k])
		// 			}
		// 		})
		// 	// }
			
		// } else {
		
// }

	var rect = []
	var tag = document.getElementsByTagName('circle')
	// 遍历传入参数，创建同等数量的svg下元素节点并绑定事件
	for (var i = 0; i < this.options.length; i++) {
		console.log(this.options)
		console.log(tag[i])
		
		var createCenter = this.map.projectToContainer(this.options[i].position);
		group[i] = document.createElementNS(SVG_NS, 'g');

		// 在中心创建一个圆形

		circleShape[i] = document.createElementNS(SVG_NS, 'circle');
		circleShape[i].setAttribute('style', 'fill: #2F72F5;stroke:#FFFFFF;opacity:0.9;');
		circleShape[i].setAttribute('cx', createCenter.x);
		circleShape[i].setAttribute('cy', createCenter.y);
		circleShape[i].setAttribute('r', 25);
		group[i].appendChild(circleShape[i]);
		console.log(group[i])
		// 绘制文字
		area[i] = document.createElementNS(SVG_NS, 'text');
		area[i].setAttribute('x', createCenter.x);
		area[i].setAttribute('y', createCenter.y); // +5是为了让文字向下偏移5像素，使文字居中
		area[i].setAttribute('text-anchor', 'middle');
		area[i].setAttribute('fill', '#FFFFFF');
		area[i].setAttribute('font-size', '14px');
		// area[i].innerHTML = this.options[i].area; //文字	
		group[i].appendChild(area[i]);


		//绘制文字
		textShape[i] = document.createElementNS(SVG_NS, 'tspan');
		textShape[i].setAttribute('x', createCenter.x);
		textShape[i].setAttribute('y', createCenter.y + 13); // +5是为了让文字向下偏移5像素，使文字居中
		textShape[i].setAttribute('text-anchor', 'middle');
		textShape[i].setAttribute('fill', '#FFFFFF');
		textShape[i].setAttribute('font-size', '10px');
		textShape[i].innerHTML = this.options[i].text; //文字					
		svg.appendChild(group[i]);
		area[i].appendChild(textShape[i]);

		//绘制文字
		textShape1[i] = document.createElementNS(SVG_NS, 'tspan');
		textShape1[i].setAttribute('x', createCenter.x);
		textShape1[i].setAttribute('y', createCenter.y); // +5是为了让文字向下偏移5像素，使文字居中
		textShape1[i].setAttribute('text-anchor', 'middle');
		textShape1[i].setAttribute('fill', '#FFFFFF');
		textShape1[i].setAttribute('font-size', '12px');
		textShape1[i].innerHTML = this.options[i].area; //文字					
		svg.appendChild(group[i]);
		area[i].appendChild(textShape1[i]);

		this.onMouseEnter = function(e) {
			// DOMOverlay继承自EventEmitter，可以使用emit触发事件
			// 动态修改circle颜色，所以选择传入circle节点
			this.emit('mouseenter', e.target.firstChild);
		}.bind(this);
		this.onMouseLeave = function(e) {
			this.emit('mouseleave', e.target.firstChild);
		}.bind(this);

	// 	group[i].addEventListener('mouseenter', this.onMouseEnter);
	// 	group[i].addEventListener('mouseleave', this.onMouseLeave);
	

		group[i].addEventListener('mouseenter', this.onMouseEnter);
		group[i].addEventListener('mouseleave', this.onMouseLeave);
	}

	console.log(svg)
	return svg;
};

// 更新DOM元素，在地图移动/缩放后执行

SvgMarker.prototype.updateDOM = function() {
	console.log(this.map)
	var zoomLevel = this.map.getZoom()
	var mapCenter = this.map.getCenter(); //获取地图中心点坐标
	console.log(zoomLevel)
	//     // centerInfo.innerHTML = "当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6);
	// // console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6))
	// // var num = Number(zoomLevel.toString().slice(3,4))
	this.map.setCenter(mapCenter)
	// this.center = new TMap.LatLng(mapCenter.getLat().toFixed(6), mapCenter.getLng().toFixed(6))
	if (!this.map) {
		return;
	}
	  //获取地图中心点事件
	                 
	        //     var mapCenter = this.map.getCenter(); //获取地图中心点坐标
	        //     // centerInfo.innerHTML = "当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6);
	        // console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6))
	// const delay = 500
	// let timer = null
	// clearInterval(timer)
	// timer = setTimeout(function(){
		
	// })
	console.log(this.options)
	// 经纬度坐标转容器像素坐标
	if (zoomLevel<=13) {
		$('#svgDom').css("display","block")
		$('.info').css("display","none")
		$('.info').prevAll().css("display","none")
	for (var j = 0; j < this.options.length; j++) {

		
		console.log(this.dom.children[j])
		var pixel = this.map.projectToContainer(this.options[j].position);
		var updateCenter = this.map.projectToContainer(this.options[j].position);
console.log(updateCenter)
		
		// 	this.dom.children[j]
		// 		.getElementsByTagName('rect')[0]
		// 		.setAttribute('x', updateCenter.x);
		// 	this.dom.children[j]
		// 		.getElementsByTagName('rect')[0]
		// 		.setAttribute('y', updateCenter.y);
		// 		// this.dom.children[j]
		// 		// 	.getElementsByTagName('text')[0]
		// 		// 	.setAttribute('x', updateCenter.x);
		// 		// this.dom.children[j]
		// 		// 	.getElementsByTagName('text')[0]
		// 		// 	.setAttribute('y', updateCenter.y);
		// } else {
			this.dom.children[j]
				.getElementsByTagName('circle')[0]
				.setAttribute('cx', updateCenter.x);
			this.dom.children[j]
				.getElementsByTagName('circle')[0]
				.setAttribute('cy', updateCenter.y);
				this.dom.children[j]
					.getElementsByTagName('text')[0]
					.setAttribute('x', updateCenter.x);
				this.dom.children[j]
					.getElementsByTagName('text')[0]
					.setAttribute('y', updateCenter.y);
				this.dom.children[j]
					.getElementsByTagName('tspan')[0]
					.setAttribute('x', updateCenter.x);
				this.dom.children[j]
					.getElementsByTagName('tspan')[0]
					.setAttribute('y', updateCenter.y + 13);
				this.dom.children[j]
					.getElementsByTagName('tspan')[1]
					.setAttribute('x', updateCenter.x);
				this.dom.children[j]
					.getElementsByTagName('tspan')[1]
					.setAttribute('y', updateCenter.y);
		}
		
	}else{
		$('#svgDom').css("display","none")
		$('.info').css("display","block")
		$('.info').prevAll().css("display","block")
}
}
export default SvgMarker
