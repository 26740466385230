<template>
	<div class="map">
		<!-- <van-dropdown-menu>
			<van-dropdown-item v-model="value1" :options="option1" />
			<van-dropdown-item v-model="value2" :options="option2" />
			<van-dropdown-item v-model="value3" :options="option3" />
		</van-dropdown-menu> -->


		<div id="container" style="z-index: 5;"></div>



		<div id="listIcon" style="position: fixed;bottom: 10px;right: 15px;z-index: 999;" v-if="storeList.length>0">
         
			 <div  style="display: flex;flex-direction: column;background-color: #ffffff;padding: 3px 8px;"  @click="show = true">
				  <van-icon name="wap-nav" size="25" />
				  <span style="font-size: 10px;">列表</span>
			 </div>
			 
		
			
			
		</div>

<van-action-sheet v-model:show="show" title="">
			  <div class="store-list">
				  <van-list
				    v-model="loading"
				    :finished="finished"
				    finished-text="没有更多了"
				    @load="onLoad"
				  >
				  <div v-for="item in storeList" class="store-item">
					  <img :src="item.image" alt="" class="store-image">
					  <div class="store-content">
						  <p class="store-name">{{item.store_name}}</p>
						 <div class="store-rate">
							 <van-rate v-model="item.score" :size="15" color="#FB9A00" void-icon="star"
							 	void-color="#eee" />
							 <span>{{item.score}}分</span>
						 </div>
						  <div class="store-position">
							  <p class="store-address">{{item.address}}</p>
							  <span class="store-distance">距离{{item.distance}}m</span>
						  </div>
					  </div>
				  </div>
				    
				  </van-list>
			  </div>
			</van-action-sheet>
	</div>
</template>
<script>
	import * as mapApi from '@/api/map'
	import SvgMarker from '../../utils/SvgMarker.js'
	import {
		ref
	} from 'vue';
	import {
		reactive
	} from 'vue';

	import {
		toUrl
	} from '@/utils/tools'
	import {
		Dialog,
		Toast
	} from 'vant';
	import {getCurrentInstance} from 'vue'

	export default {
		setup() {
			const show = ref(false);
			const actions = [{
					name: '选项一'
				},
				{
					name: '选项二'
				},
				{
					name: '选项三'
				},
			];
			const onSelect = (item) => {
				// 默认情况下点击选项时不会自动收起
				// 可以通过 close-on-click-action 属性开启自动收起
				show.value = false;
				Toast(item.name);
			};
			return {
				show,
				actions,
				onSelect,
			};
		},
		data() {
			return {
				 loading: false,
				      finished: false,
				storeList:[],
				city: {
					"id": 184,
					"name": "郑州市",
					"code": "410100",
					"parent_code": "410000",
					"level": 2,
					"lng": "113.665413",
					"lat": "34.757977",
					"pinyin": "zhengzhoushi"
				},
				show: false,
				centerInfo: {},
				actions: [{
					name: '选项一'
				}, {
					name: '选项二'
				}, {
					name: '选项三'
				}],
				value1: 0,
				value2: 0,
				value3: 0,
				option1: [{
						text: '郑州市',
						value: 0
					},
					{
						text: '北京市',
						value: 1
					},
					{
						text: '上海市',
						value: 2
					},
					{
						text: '郑州市',
						value: 3
					},
					{
						text: '北京市',
						value: 4
					},
					{
						text: '上海市',
						value: 5
					},
					{
						text: '郑州市',
						value: 6
					},
					{
						text: '北京市',
						value: 7
					},
					{
						text: '上海市',
						value: 8
					},
				],
				option2: [{
						text: '全部类型',
						value: 0
					},
					{
						text: '好评排序',
						value: 1
					},
					{
						text: '销量排序',
						value: 2
					},
				],
				option3: [{
						text: '距离',
						value: 0
					},
					{
						text: '好评排序',
						value: 1
					},
					{
						text: '销量排序',
						value: 2
					},
				],

			}
		},
		computed: {
		    location: function () {
				
					 return this.$store.state.app.latAndLng
			
		       
		    },
		   
		},
		mounted() {
const instance = getCurrentInstance()
console.log(instance,'当前组件的实例')
console.log(JSON.stringify(this.location)+'缓存的经纬度')
			var params = {}
			params.lat = this.location.latitude
			params.lng = this.location.longitude

			this.getMapArea(params).then(res => {
				console.log(res)
				// var options =[]
				// var option = {}
				// res.data.forEach(i=>{
				// 	option.position =  new TMap.LatLng(i.lat, i.lng)
				// 	option.area = i.county_name
				// 	option.text = i.count
				// })
				// console.log(option)
				this.initMap(res.data,instance)
			})






		},
		methods: {
			//获取商家列表
			getStore(){
				var params = {}
				params.lat = this.location.latitude
				params.lng = this.location.longitude
				params.page = 1
				params.limit = 20
				return mapApi.getMapAreaStore(params)
			},
			//获取地图中心点事件
			getCenter() {
				var mapCenter = map.getCenter(); //获取地图中心点坐标
				this.centerInfo.lat = mapCenter.getLat().toFixed(6)
				this.centerInfo.lng = mapCenter.getLng().toFixed(6);
				console.log(this.centerInfo)
			},
			getMapArea(params) {

				return mapApi.getMapArea(params)
			},
			onSelect(item) {
				// 默认情况下点击选项时不会自动收起
				// 可以通过 close-on-click-action 属性开启自动收起
				this.show = false;
				this.$toast(item.name);
			},
			initMap(params,that) {

				// 初始化地图
				console.log(that)
				var instance = getCurrentInstance()
				console.log(instance)
				var options = []
				var option = {}
				var location ={}
				Object.assign(location,this.location)
				if(params){
					params.forEach(i => {
						option.position = new TMap.LatLng(i.lat, i.lng),
							option.area = i.county_name
						option.text = i.count
						options.push(option)
					})
				}
				console.log(options)

				var map = new TMap.Map('container', {
					zoom: 11, // 设置地图缩放级别
					minZoom: 8,
					maxZoom: 18,
					center: new TMap.LatLng(location.latitude, location.longitude) // 设置地图中心点坐标



				});
				// 初始化自定义marker类
				//初始化infoWindow

				var marker = new SvgMarker({
					map,
					options: options,
					infoWindow: ''



				});
				map.res
				marker.on('mouseenter', function(e) {
					e.setAttribute('style', 'fill: red;stroke:red; opacity:0.9;');
				});
				marker.on('mouseleave', function(e) {
					e.setAttribute('style', 'fill: blue;stroke:#FFFFFF;opacity:0.9;');

				});


				// 监听地图容器变化，动态修改svg图层大小，防止更新时元素移出svg图区
				map.on('resize', function() {
					document.getElementById('svgDom').setAttribute('width', mapContainer.offsetWidth);
					document.getElementById('svgDom').setAttribute('height', mapContainer.offsetHeight);
				})

				map.on("tilesloaded", function() {
					console.log("地图瓦片已加载完成")
					var mapCenter = this.getCenter(); //获取地图中心点坐标
					    // centerInfo.innerHTML = "当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6);
					// console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6))
					// var num = Number(zoomLevel.toString().slice(3,4))
					this.center = new TMap.LatLng(mapCenter.getLat().toFixed(6), mapCenter.getLng().toFixed(6))
					var location = {}
					location.lat = this.center.lat
					location.lng = this.center.lng
					if (this.getZoom() >= 13) {
						mapApi.getMapAreaDetail(location).then(res => {
							var options = []
							var option = {}
							console.log(res)
							// marker.options = res.data.list
							res.data.list.forEach(i => {

								marker.infoWindow = new TMap.InfoWindow({
									map: map,
									zIndex: 0,
									offset: {
										x: 0,
										y: 0
									}, //无偏移
									position: new TMap.LatLng(i.lat, i.lng), //设置信息框位置
									content: '<div class="info_card back'+i.type.toString()+'"><span class="content">' +
																			i.merchant_name + '</span></div>' //设置信息框内容
										
								});
								$('#svgDom')[0].style.display = 'none'
								var card = $('.info_card').parent()
								card.addClass("info")
								$('#listIcon').css('display','none')
							})

						})
					}else{
						$('#listIcon').css('display','block')
						console.log(that)
						 mapApi.getMapAreaStore(location).then(res=>{
							
							 that.data.storeList = [...res.data]
							
							
						 })
						
					}
				})
				map.on('scale_changed', (e) => {
					console.log(e);
				});
				//监听缩放级别
				map.on('zoom_changed', function() {
					var zoomLevel = map.getZoom();
					this.zoom = zoomLevel
					map.setZoom(zoomLevel)
					// map.setScale(Math.ceil(zoomLevel));
					var params = {}
					params.county = 410105
					var mapCenter = this.getCenter(); //获取地图中心点坐标
					//     // centerInfo.innerHTML = "当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6);
					// // console.log("当前地图中心为: " + mapCenter.getLat().toFixed(6) + "," + mapCenter.getLng().toFixed(6))
					// // var num = Number(zoomLevel.toString().slice(3,4))
					this.center = new TMap.LatLng(mapCenter.getLat().toFixed(6), mapCenter.getLng().toFixed(6))
					// if (this.zoom > 13) {
					// 	var location = {}
					// 	location.lat = this.center.lat
					// 	location.lng = this.center.lng
					// 	console.log((zoomLevel- this.zoom)%1)
					// 	if((zoomLevel- this.zoom)%1 == 1){
					// 		mapApi.getMapAreaDetail(location).then(res => {
					// 			var options = []
					// 			var option = {}
					// 			console.log(res)
					// 			res.data.list.forEach(i => {
					// 				// option.position = new TMap.LatLng(i.lat, i.lng)
					// 				// option.text = i.merchant_name
					// 				// options.push(option)
					// 				var infoWindow = new TMap.InfoWindow({
					// 					map: map,
					// 					position: new TMap.LatLng(i.lat, i.lng), //设置信息框位置
					// 					content: '<div class="info_card"><div align="left" class="content">' +i.merchant_name+'</div><span class="cancle bot"></span><span class="cancle top"></span></div>' //设置信息框内容
					// 				});
					// 				var card = $('.info_card').parent()
					// 				console.log(card)
					// 			})
					// 			// marker.options = options
					// 			// console.log(options)
					// 			// marker.createDOM(zoomLevel)

					// 		})
					// 	}



					// }




				})

			}


		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";
.store-list{
	padding: 15px;
	width: 100%;
	.store-item{
		width: 100%;
		display: flex;
		border-bottom: solid 1px #F2BFBF;
		margin-bottom: 10px;
		box-sizing: border-box;
		.store-image{
			width: 102px;
			height: 68px;
			border-radius: 5px;
		}
		.store-content{
			display: flex;
			width: calc(100% - 142px);
			margin-left: 10px;
			flex-direction: column;
			.store-name{
				font-size: 16px;
				font-weight: bold;
				margin: 0;
			}
			.store-rate{
				margin-top: 10px;
				display: flex;
				align-items: center;
				span{
					font-size: 12px;
					margin-left: 5px;
					
				}
			}
			.store-position{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.store-address{
					font-size: 10px;
					color: #666666;
				}
				.store-distance{
					font-size: 10px;
					color: #999999;
					flex-shrink: 0;
				}
			}
		}
	}
}
	::v-deep .info {
		width: 60px !important;
		height: 25px !important;
		padding: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#svgDom+div {
		width: 60px;
		height: 20px;
	}

	::v-deep .info~div {
		display: none;
	}
	::v-deep .back0{
		background-color: #2F72F5;
	}
::v-deep .back1{
	background-color: #FC6228;
}
::v-deep .back2{
	background-color: #56BCD5;
}
::v-deep .back3{
	background-color: #9742FF;
}
	::v-deep .info_card {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 25px;
		min-width: 100%;
		padding: 0 5px;
		height: 80%;
		position: absolute;
		left: 0;
		top: 0;
		// background-color: #FFFFFF;
		color: #FFFFFF;
		font-size: 12px;
	}

	.map {
		#container {
			/*地图(容器)显示大小*/
			width: 100%;
			height: 100vh;

		}

		background-color: #F6F6F6;


	}
</style>
