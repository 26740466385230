import request from '@/utils/request'


//获取地图商家区域
export function getMapArea(params) {
    return request({
        url: '/map/index',
        method: 'post',
        isLogin: false,
		params
    })
}
//获取地图商家区域详情
export function getMapAreaDetail(params) {
    return request({
        url: '/map/detail',
        method: 'post',
        isLogin: false,
		async: false,
		params
    })
}
//获取地图商家列表
export function getMapAreaStore(params) {
    return request({
        url: '/map/store',
        method: 'post',
        isLogin: false,
		async: false,
		params
    })
}